/*global AEGNewsletterWidget */
// @flow
import { observable, action } from 'mobx';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { graphql } from 'gatsby';
import { Provider } from 'mobx-react';
// import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
// import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';

import { JSON_NEWSLETTER } from '../constants/json';
import { STORE_JSON, STORE_UI } from '../constants/stores';
import JsonStore from '../stores/json-store';
import UiStore from '../stores/ui-store';
import styles from './newsletter.module.scss';
import Metadata from "../components/Metadata";


type NewsletterProps = {
  json: JsonStore,
  ui: UiStore,
  data: any,
  location: any,
}

@inject(STORE_JSON, STORE_UI)
@observer
class NewsletterPage extends React.Component<NewsletterProps, any> {

  ref: any;
  stores: any;

  submit: any = {};
  submitUrl: any = '';
  requiredFields: any = {};

  constructor(props: NewsletterProps, context: any) {
    super(props, context);
    this.props[STORE_JSON].setJsonFromGraphQL(this.props.data, JSON_NEWSLETTER);
  }

  @observable displayData: any[] = [];

  @action
  setDisplayData = (data: any[]): void => {
    this.displayData = data;
  };

  @observable checked: any = {};

  @action
  setChecked = (key: any, checked: boolean, value: string): void => {
    this.setCheckedError(key, false);
    this.input(checked ? value : '', key);
    this.checked = Object.assign({}, this.checked, {
      [key]: checked
    });
  };

  @observable checkedErrors: any = {};

  @action
  setCheckedError = (key: string, checked: boolean): void => {
    this.checkedErrors = Object.assign({}, this.checkedErrors, {
      [key]: checked
    });
  };

  componentDidMount() {
    if (process.env.GATSBY_PREVIEW === 'true' && window) {
      this.props[STORE_JSON].getData(JSON_NEWSLETTER, {
        contentType: 'newsletter',
        id: '1OiCIQr6rui6C8Aeu80QC6'
      });
    }
    if (window) {
      const newsletterScript = document.createElement('script');

      newsletterScript.src = `https://aeg-wt13-prod.trafficmanager.net/aeg-mc-newsletter.js`;
      newsletterScript.onload = () => {
        AEGNewsletterWidget.init({
          // the element ID for the placeholder div in the body. Required.
          "elementId": "aeg-newsletter",

          // the access key as registered by the proxy server
          "accessKey": 'JDJ5JDEwJG4zdFh1c0RINEl3eUVDTWJwY0lyRi5ocWlJbjZ2LmlkMFp2cnRZaHkyVmZEay9UNHJyT3JX',

          // for testing, the baseUrl can be overridden by uncommenting the line below.  Optional. It defaults to the production URL.
          // "baseUrl": "https://aeg-wt12-staging.trafficmanager.net",

          // Alternatively, use the staging endpoint for test submissions on the production URL, set 'staging' to true. Optional. Defaults to false if omitted.
          // "staging": true,

          // The submit button label. Required
          "submitLabel": "Submit",

          // The submit button label post-submission. Required.
          "confirmLabel": "Thank You!",

          // An array of fields to display.  All the possible types are displayed in the sample.
          // Only the type 'email' is required.  All other types may be omitted from the formFields array.
          // Only the types 'email', 'mobile' and 'zip' are validated.
          // The form fields appear in the same order they are configured in.
          // Each entry in the formFields array must contain the following fields:
          // * type: The field type. All possible values are listed in the sample.
          // * label: Label used for the form input field. Can be an empty string. Ignored for consent types.
          // * content: The content to be displayed in the input field.
          //            For an text input, this is the placeholder text.
          //            For regional_selector type, this is a comma-separated list of selections.  First entry must be a call-to-action like "Select Region".
          //            For a consent type, this is the consent legal text.
          // * required: this is a boolean field that denotes if field is required.  If true, an error message will appear below the input field if it is blank upon submission.
          // * error: The error message that appears if field is required or validation fails.
          "formFields": [
            { "type": "first_name", "label": "First Name", "content": "Enter First Name", "required": false, "error": "First name is required." },
            { "type": "last_name", "label": "Last Name", "content": "Enter Last Name", "required": false, "error": "Last name is required." },
            { "type": "zip", "label": "Zip Code", "content": "Enter Zip Code", "required": false, "error": "A valid zip code is required." },
            { "type": "email", "label": "Email", "content": "Enter Email", "required": true, "error": "A valid email is required." },
            { "type": "email_consent", "label": "", "content": "I agree to receive emails.", "required": true, "error": "Email consent is required." }
          ]
        });
      };
      document.body.appendChild(newsletterScript);

      // fetch(`https://accord.aegeurope.com/api/?sitekey=${this.props.data.newsletter.siteKey}&app_header_id=${this.props.data.newsletter.appHeaderIdMd5}`).then((data) => {
      //   return data.json();
      // }).then((formData: any) => {
      //   if (formData) {
      //     this.submit['user_guid'] = formData.guid;
      //     this.submit['prep_key'] = formData.prepKey;
      //     this.submitUrl = formData.prepSubmitUrl;
      //     const dataFields = formData.prepDataFields['map1'] || formData.prepDataFields;
      //     let displayData: any[] = reduce(dataFields, (acc: any[], field: any) => {
      //       const model: any = {};
      //       const labelLower: string = field.field_label.toLowerCase();
      //       switch (field.field_type) {
      //         case 'text':
      //         case 'email':
      //           if (includes(labelLower, 'name')) {
      //             if (includes(labelLower, 'first')) model.autoComplete = 'given-name';
      //             if (includes(labelLower, 'last')) model.autoComplete = 'family-name';
      //           } else if (includes(labelLower, 'email')) {
      //             model.autoComplete = 'email';
      //           } else if (includes(labelLower, 'zip')) model.autoComplete = 'postal-code';
      //           model.type = 'text';
      //           model.name = `field_${field.id}`;
      //           model.label = field.field_label;
      //           model.placeholder = `${field.field_label} ${field.required ? '*' : ''}`;
      //           model.mandatory = field.required;
      //           if (field.required) this.requiredFields[model.name] = true;
      //           break;
      //         case 'dropdown':
      //           model.type = 'select';
      //           model.label = field.field_label;
      //           model.name = `field_${field.id}`;
      //           model.options = reduce(field.opts, (arr: any[], opt: any) => {
      //             arr.push({
      //               value: opt.v,
      //               display: opt.l
      //             });
      //             return arr;
      //           }, []);
      //           break;
      //       }
      //       acc.push(model);
      //       return acc;
      //     }, []);
      //     if (formData.consents) {
      //       if (formData.consents.primary_consent) {
      //         const mandatory: boolean = parseInt(formData.consents.primary_consent_mandatory) === 1;
      //         if (mandatory) this.requiredFields['selectedConsents[0]'] = true;
      //         displayData.push({
      //           type: 'checkbox',
      //           label: `${formData.consents.primary_consent.label} ${mandatory ? '*' : ''}`,
      //           name: 'selectedConsents[0]',
      //           value: 0,
      //           mandatory: mandatory
      //         });
      //       }
      //       if (formData.consents.add_consents) {
      //         reduce(formData.consents.add_consents, (acc: any[], consent) => {
      //           acc.push({
      //             type: 'checkbox',
      //             label: consent.label,
      //             name: `selectedConsents[${consent.id}]`,
      //             value: consent.id
      //           });
      //           return acc;
      //         }, displayData);
      //       }
      //     }
      //     this.setDisplayData(displayData);
      //   }
      // }).catch((e) => {
      //   console.log(e);
      // });
    }
  }

  parseSubmitModel(data: any) {
    return Object.keys(data).map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`).join('&');
  }

  input(val: string, key: string) {
    if (this.checkedErrors[key]) this.setCheckedError(key, false);
    this.submit[key] = val;
  }

  submitForm() {
    let valid: boolean = true;
    forEach(this.requiredFields, (val: any, key: string) => {
      if (isUndefined(this.submit[key]) || this.submit[key] === '') {
        valid = false;
        this.setCheckedError(key, true);
      }
    });
    if (valid) {
      fetch(this.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: this.parseSubmitModel(this.submit)
      }).then((data) => {
        return data.json();
      }).then((result) => {
        if (result.duplicate) {
          this.props.ui.setSnackbarText('You have already been subscribed.');
          this.props.ui.setSnackbarShowing(true);
        } else if (result.result === 'ok') {
          this.props.ui.setSnackbarText('Thank you for subscribing!');
          this.props.ui.setSnackbarShowing(true);
        } else {
          this.props.ui.setSnackbarText('We have encountered an error. Please try again or contact us.');
          this.props.ui.setSnackbarShowing(true);
        }
      }).catch((e) => {
        console.log(e);
        this.props.ui.setSnackbarText('We have encountered an error. Please try again or contact us.');
        this.props.ui.setSnackbarShowing(true);
      });
    }
  }

  render() {

    return (
      <Provider {...this.stores}>
        <>
          <Metadata data={this.props.data.newsletter.metadata}
            location={this.props.location} />
          <div className={styles.container}>
            <div className={styles.bg}>

            </div>
            <div className={styles.wrapper}>
              <h2>Join Our Newsletter</h2>
              <div id="aeg-newsletter" className={styles.aegNewsletter}></div>
              {/* <h5>*Required</h5>
              <form>
                {this.displayData.map((data, index) => {
                  if (data.type === 'text') {
                    return (
                      <React.Fragment
                        key={`newsletterForm${index}`}>
                        <input type="text"
                          onInput={(e) => this.input(e.target.value, data.name)}
                          name={data.name}
                          autoComplete={data.autoComplete || ''}
                          placeholder={data.placeholder} />
                        <span className={styles.error}
                          style={{
                            opacity: this.checkedErrors[data.name] ? 1 : 0
                          }}>
                          {`${data.label} is required for submission.`}
                        </span>
                      </React.Fragment>
                    )
                  } else if (data.type === 'dropdown') {
                    return (
                      <React.Fragment
                        key={`newsletterForm${index}`}>
                        <p>{data.label}</p>
                        <div className={styles.selectWrapperLarge}>
                          <select name={data.name}
                            onInput={(e) => this.input(e.target.value, data.name)}>
                            {data.options.map((option, optionIndex) => {
                              return (
                                <option value={option.v}
                                  key={`newsletterFormOption${optionIndex}`}>
                                  {option.l}
                                </option>
                              )
                            })}
                          </select>
                          <div className={styles.arrow}>
                            <svg version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 48 48">
                              <path fill="#D9B282"
                                d="M31.174,19.83c-0.419-0.451-1.096-0.451-1.514,0l-5.667,6.111l-5.666-6.111 c-0.418-0.451-1.096-0.451-1.514,0s-0.418,1.182,0,1.633l6.423,6.927c0.209,0.226,0.483,0.339,0.757,0.339 c0.274,0,0.548-0.113,0.757-0.339l6.424-6.927C31.592,21.012,31.592,20.281,31.174,19.83z" />
                            </svg>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  } else if (data.type === 'checkbox') {
                    return (
                      <React.Fragment
                        key={`newsletterForm${index}`}>
                        <div className={styles.checkbox}>
                          <div className={styles.check}
                            onClick={() => this.setChecked(data.name, !this.checked[data.name], data.value)}>
                            <svg style={{
                              transform: this.checked[data.name] ? 'none' : 'scale(0)'
                            }}
                              version="1.1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 48 48">
                              <path fill="#D9B282"
                                d="M32.081,16.187c-0.448-0.321-1.073-0.217-1.395,0.232l-9.314,13.039l-4.165-4.165 c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l5,5C20.981,31.896,21.236,32,21.5,32c0.027,0,0.055-0.001,0.082-0.003 c0.293-0.024,0.561-0.177,0.731-0.416l10-14C32.635,17.132,32.53,16.507,32.081,16.187z" />
                            </svg>
                          </div>
                          <h4 dangerouslySetInnerHTML={{
                            __html: data.label
                          }}>

                          </h4>
                        </div>
                        <span className={styles.error}
                          style={{
                            opacity: this.checkedErrors[data.name] ? 1 : 0
                          }}>
                          Consent is required for submission.
                        </span>
                      </React.Fragment>
                    )
                  }
                  return (
                    <React.Fragment key={`newsletterForm${index}`}>

                    </React.Fragment>
                  )
                })}
                <div className={styles.button}
                  onClick={() => this.submitForm()}>
                  <span>
                    Submit
                  </span>
                </div>
              </form> */}
            </div>
          </div>
        </>
      </Provider>
    )
  }
}

export const query = graphql`
query {
  newsletter: contentfulNewsletter(contentful_id: {eq: "1OiCIQr6rui6C8Aeu80QC6"}) {
    metadata {
      title
      description
      image {
        file {
          url
        }
      }
      ogTitle
      ogDescription
      twitterCard
    }
    url
    headerText
    appHeaderIdMd5
    siteKey
  }
}`;

export default NewsletterPage;
